import React from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export default function ModalRetornarPendente(props) {
  const retornarParaPendente = async () => {
    try {
      const resultado = await api.post('/api/pontuacoes/retornar-foto-pendente', {
        identificador: props.pontuacao
      });
      if (resultado?.data?.mensagem) {
        toast.success(resultado.data.mensagem, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide();
        historico.push('/painel/processar/pontuacoes');
      }
    } catch (error) {
      const mensagem = error.response?.data?.erro || 'Erro ao retornar pontuação para pendente';
      toast.error(mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Retornar para Pendente</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
          <span>Deseja retornar essa foto para pendente?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          <button
            type="button"
            className="btn btn-primario m-1"
            onClick={retornarParaPendente}
          >
            SIM
          </button>
          <button
            type="button"
            className="btn btn-cinza m-1"
            onClick={props.onHide}
          >
            NÃO
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}