/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalMidia from '../ModalMidia';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import ModalRetornarPendente from '../ModalRetornarPendente';

export default function PontuacoesEncerradas() {
  const [data, setData] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [linkArquivo, setLinkArquivo] = useState(null);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [tipo, setTipo] = useState('');
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState('criadoEm');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [mostrarModalRetornar, setMostrarModalRetornar] = useState(false);
  const [pontuacaoSelecionada, setPontuacaoSelecionada] = useState(null);
  const [dataInicial, setDataInicial] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [dataFinal, setDataFinal] = useState(moment().format('YYYY-MM-DD'));
  const [arquivo, setArquivo] = useState([]);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    buscarRedes();
  }, []);

  const columns = [
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      text: 'Código Loja',
      dataField: 'codRevenda',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Data do Cancelamento',
      dataField: 'atualizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.atualizadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Foto da Venda',
      dataField: 'linkArquivo',
      formatter: (celula, valor) =>
        valor.linkArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalMidia(valor.linkArquivo, valor.tipoArquivo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
    {
      text: 'Ações',
      dataField: 'id',
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => visualizarModalRetornar(valor.id)}
        >
          <i className="fas fa-undo text-primary"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'criadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async ({ page: newPage, sizePerPage: newPerPage, sortField: newSortField, sortOrder: newSortOrder } = {}) => {
    setBuscando(true);
    const params = new URLSearchParams();
    params.append('page', newPage || page);
    params.append('perPage', newPerPage || sizePerPage);
    params.append('dataInicial', dataInicial);
    params.append('dataFinal', dataFinal);
    
    if (redeSelecionada) {
      params.append('rede', redeSelecionada);
    }
    if (searchTerm) {
      params.append('search', searchTerm);
    }
    if (newSortField || sortField) {
      params.append('sortField', newSortField || sortField);
      params.append('sortOrder', newSortOrder || sortOrder);
    }

    try {
      const resultado = await api.get(`/api/pontuacoes/encerradas?${params.toString()}`);
      if (resultado?.data?.pontuacao) {
        setData(resultado.data.pontuacao);
        setTotalSize(resultado.data.totalRecords);
        if (newPage) setPage(newPage);
        if (newPerPage) setSizePerPage(newPerPage);
        if (newSortField) setSortField(newSortField);
        if (newSortOrder) setSortOrder(newSortOrder);
      } else {
        setData([]);
        setTotalSize(0);
      }
    } catch (error) {
      console.error('Erro ao buscar pontuações:', error);
      setData([]);
      setTotalSize(0);
    }
    setBuscando(false);
  };

  const visualizarModalMidia = (linkArquivo, tipo) => {
    if (!linkArquivo) return;

    if (tipo !== 'image') {
      window.open(linkArquivo, '_blank');
    } else {
      setArquivo(linkArquivo);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const handleTableChange = (type, newState) => {
    if (newState.sortField && newState.sortField !== sortField) {
      setSortField(newState.sortField);
    }
    if (newState.sortOrder && newState.sortOrder !== sortOrder) {
      setSortOrder(newState.sortOrder);
    }
    if (newState.page !== page) {
      setPage(newState.page);
    }
    if (newState.sizePerPage !== sizePerPage) {
      setSizePerPage(newState.sizePerPage);
    }

    buscarPontuacoes(newState);
  };

  useEffect(() => {
    if (redeSelecionada !== undefined) {
      setPage(1);
      buscarPontuacoes({ page: 1 });
    }
  }, [redeSelecionada]);

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const handleSearch = () => {
    setPage(1);
    buscarPontuacoes({ 
      page: 1,
      sizePerPage,
      sortField,
      sortOrder,
      search: searchTerm 
    });
  };

  const visualizarModalRetornar = (id) => {
    setPontuacaoSelecionada(id);
    setMostrarModalRetornar(true);
  };

  const fecharModalRetornar = () => {
    setMostrarModalRetornar(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações Encerradas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="align-items-center mb-3">
        <Col xs={12} sm={4}>
          <div>
            <label className="mb-1">Selecione uma Rede</label>
            <select
              className="input-select"
              name="redeSelecionada"
              id="redeSelecionada"
              onChange={(e) => setRedeSelecionada(e.target.value)}
              defaultValue=""
              style={{ height: '38px', width: '100%' }}
            >
              <option value="">Todas</option>
              {redesSelect.map((rede) => (
                <option value={rede.nome} key={rede.nome}>
                  {rede.nome}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col xs={12} sm={2}>
          <div>
            <label className="mb-1">De</label>
            <input
              type="date"
              className="input-theme"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
              max="2999-12-31"
              style={{ height: '38px', width: '100%' }}
            />
          </div>
        </Col>
        <Col xs={12} sm={2}>
          <div>
            <label className="mb-1">Até</label>
            <input
              type="date"
              className="input-theme"
              onChange={(e) => setDataFinal(e.target.value)}
              value={dataFinal}
              max="2999-12-31"
              style={{ height: '38px', width: '100%' }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1}>
          <div style={{ marginTop: '24px' }}>
            <button
              className="btn-busca"
              type="submit"
              onClick={() => {
                setPage(1);
                buscarPontuacoes({ page: 1 });
              }}
              style={{ height: '38px' }}
            >
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <i className="fas fa-search"></i>
              )}
            </button>
          </div>
        </Col>
        <Col xs={12} sm={3} className="d-flex justify-content-end">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginTop: '24px'
          }}>
            <input
              type="text"
              placeholder="Buscar"
              className="input-theme"
              style={{
                width: '200px',
                height: '38px'
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <button
              className="btn-busca"
              type="submit"
              onClick={handleSearch}
              style={{
                height: '38px',
                minWidth: '38px',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
        >
          {(props) => (
            <div>
              <BootstrapTable
                remote={{ pagination: true, sort: true }}
                onTableChange={handleTableChange}
                loading={buscando}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        linkArquivo={arquivo}
        tipo={tipo}
      />
      <ModalRetornarPendente
        show={mostrarModalRetornar}
        onHide={fecharModalRetornar}
        pontuacao={pontuacaoSelecionada}
      />
    </Container>
  );
}
