import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export default function ModalCancelarPontuacao(props) {
  const [processando, setProcessando] = useState(false);

  const retornarParaPendente = async () => {
    setProcessando(true);
    try {
      const resultado = await api.post('/api/pontuacoes/retornar-pontuacao-pendente', {
        identificador: props.pontuacao,
      });

      if (resultado?.data?.mensagem) {
        toast.success(resultado.data.mensagem, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide(props.pontuacao);
        historico.push('/painel/processar/pontuacoes');
      }
    } catch (error) {
      const mensagem = error.response?.data?.erro || 'Erro ao retornar pontuação para pendente';
      toast.error(mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setProcessando(false);
    }
  };

  const cancelarPontuacao = async () => {
    setProcessando(true);
    try {
      const resultado = await api.post('/api/pontuacoes/cancelar', {
        identificador: props.pontuacao,
      });

      if (resultado?.data?.mensagem) {
        toast.success(resultado.data.mensagem, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide(props.pontuacao);
        historico.push('/painel/processar/pontuacoes');
      }
    } catch (error) {
      const mensagem = error.response?.data?.erro || 'Erro ao cancelar pontuação';
      toast.error(mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setProcessando(false);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancelar Pontuação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
          <span>Gostaria de retornar essa pontuação para pendente ou realmente cancelar?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {processando ? (
            <button
              type="button"
              className="btn btn-primary m-1"
              disabled
            >
              PROCESSANDO...
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={retornarParaPendente}
              >
                RETORNAR PARA PENDENTE
              </button>
              <button
                type="button"
                className="btn btn-danger m-1"
                onClick={cancelarPontuacao}
              >
                CANCELAR PONTUAÇÃO
              </button>
              <button
                type="button"
                className="btn btn-secondary m-1"
                onClick={props.onHide}
              >
                FECHAR
              </button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
